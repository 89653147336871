import { Controller } from "@hotwired/stimulus";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
  }
  initializeTribute() {
    this.tribute = new Tribute({
      trigger: "@",
      allowSpaces: true,
      lookup: "name",
      values: this.fetchEquipment,
      requireLeadingSpace: true,
      searchOpts: {
        pre: '<span class="bg-red-500 text-purple-500">',
        post: '</span>',
        skip: false // true will skip local search, useful if doing server-side search
      },
    });
    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced);
  }

  replaced(event) {
    let mention = event.detail.item.original;
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
    // this.editor.insertString(mention.name)
  }

  fetchEquipment(text, callback) {
    fetch(`/mentions.json?q=${text}`)
      .then((res) => res.json())
      .then((json) => {
        callback(json);
      })
      .catch((err) => callback([]));
  }

  _pasteHtml(html, startPos, endPos) {
    this.editor = this.fieldTarget.editor;

    let position = this.editor.getPosition();
    let tributeLength = endPos - startPos;
    let trixStartPos = position - tributeLength;
    let trixEndPos = position;
    this.editor.setSelectedRange([trixStartPos, trixEndPos]);
    this.editor.deleteInDirection("backward");
  }
}

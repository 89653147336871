import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    valueid: String,
    autosubmit: Boolean,
  };

  onValueChanged(event) {
      console.log("autocomplete_helper_controller: autosubmit", this.autosubmitValue)
    if (this.autosubmitValue) {
      const form = this.element.closest("form");
      console.log("autocomplete_helper_controller.js: onValueChanged: form", form)
      if (form) {

        form.requestSubmit();
      }
    }
    if (this.valueidValue == null) {
      return;
    }

    const valueField = document.getElementById(this.valueidValue);
    if (!valueField) {
      return;
    }
    if (event.detail.value === event.detail.textValue) {
      return;
    }
    if (event.detail.value === "NULL") {
      return;
    }

    if (valueField.iMaskObject) {
      valueField.iMaskObject.value = event.detail.value;
    } else {
      valueField.value = event.detail.value;
    }

    const inputEvent = new InputEvent("input", { data: event.detail.value, inputType: "insertText" });
    valueField.dispatchEvent(inputEvent);
  }
}

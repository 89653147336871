// Entry point for the build script in your package.json


const WORKER_VERSION = "v1";
const CACHE_KEY_WORKER = ":sw-cache-worker:version";

const actual_worker_version = localStorage.getItem(CACHE_KEY_WORKER);

const isWorkerCurrent = actual_worker_version === WORKER_VERSION;

import 'alpine-turbo-drive-adapter'
import Alpine from "alpinejs";
import anchor from "@alpinejs/anchor";
// import mask from "@alpinejs/mask";
import intersect from "@alpinejs/intersect";
import focus from "@alpinejs/focus";
// import collapse from "@alpinejs/collapse";
Alpine.plugin(anchor);
// Alpine.plugin(mask);
Alpine.plugin(intersect);
Alpine.plugin(focus);

import itemComboBox from "./alpine/components/item_combo_box";
Alpine.data("itemComboBox", itemComboBox);


// Alpine.plugin(collapse);
window.Alpine = Alpine;
Alpine.start();

import "@hotwired/turbo-rails";
import "./controllers";
import "trix";
import "@rails/actiontext";
import "chartkick/chart.js";


console.log("[PWA]", "Hello from app/javascript/application.js!");
if (navigator.serviceWorker) {
  if (!isWorkerCurrent) {
    console.log("[PWA]", "unregistering old service worker");
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
  console.log("[PWA] Registering service worker", "service-worker.js");
  navigator.serviceWorker
    .register("/service-worker.js", { scope: "/" })
    .then(function (registration) {
      console.log("[PWA]", "Service worker registered!");
      console.log(registration);
      localStorage.setItem(CACHE_KEY_WORKER, WORKER_VERSION);
    })
    .catch(function (error) {
      console.log("[PWA]", "Service worker registration failed:", error);
    });
} else {
  console.log("no service worker");
}


function debounce(func, timeout = 500){
  console.log("debounce setup", func, timeout)
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      console.log("debounce::calling function")
      func.apply(this, args); 
    }, timeout);
  };
}

function reqSub(form) { 
    form.requestSubmit();
}

window.reqSub = debounce(reqSub, 250);

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fix-enter-on-form"
export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.handleKeydown)
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.handleKeydown)
  }

  handleKeydown(event) {
    if (event.keyCode == 13) { 
      event.preventDefault()
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Location Guard Connected");
  }

  onPaste(event) {
    const input = event.clipboardData.getData("text").trim().toLowerCase();

    if (/https?:\/\//.test(input)) {
      event.preventDefault();
      document.getElementById("draft_location").value = "";
      const pinElement = document.getElementById("draft_location_pin");
      const cleanInput = input.replace(/^.*http/, "http").replace(/\s.*$/, "");
      pinElement.value = cleanInput;
      pinElement.focus();
    }
  }
}

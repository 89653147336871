// Example usage:
// <div data-controller="tooltip" data-tooltip-content-value="Hello world"></div>

import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    content: String,
    placement: String,
    delay: Number,
  };

  connect() {
    let options = {
      theme: "material",
      allowHTML: true,
      animation: "fade",
      followCursor: false,
      delay: this.hasDelayValue ? this.delayValue : 0,
    };
    if (this.hasContentValue) {
      options["content"] = this.contentValue;
    }
    if (this.hasPlacementValue) {
      options["placement"] = this.placementValue;
    }
    this.tippy = tippy(this.element, options);
  }

  disconnect() {
    this.tippy.destroy();
  }
}

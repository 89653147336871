import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "address",
    "city",
    "streetNumber",
    "route",
    "postalCode",
    "country",
    "county",
    "state",
    "longitude",
    "latitude",
    "map",
  ];

  static values = {
    country: Array,
  };

  initialize() {
    this.placeChanged = this.placeChanged.bind(this);
  }

  connect() {
    if (typeof google !== "undefined") {
      this.initAutocomplete();
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions);

    this.autocomplete.addListener("place_changed", this.placeChanged);
  }

  guardResetGeometry() {
    if (this.hasLongitudeTarget) this.longitudeTarget.value = null;
    if (this.hasLatitudeTarget) this.latitudeTarget.value = null;
    console.log("Reset Geometry");
  }

  placeChanged(event) {
    this.place = this.autocomplete.getPlace();
    const addressComponents = this.place.address_components;

    if (addressComponents !== undefined) {
      const formattedAddress = this.formatAddressComponents(addressComponents);

      this.addressTarget.form.dispatchEvent(new Event("change"));

      this.setAddressComponents(formattedAddress);
    }

    if (this.place.geometry !== undefined) {
      this.setGeometry(this.place.geometry);

      if (this.mapTarget) {
        const lat = this.place.geometry.location.lat();
        const lng = this.place.geometry.location.lng();
        const url = `https://www.google.com/maps/embed/v1/place?key=AIzaSyA3GiihgOydJwgQxtkCba8rLi39BcEkBtc&q=${lat},${lng}`;
        this.mapTarget.src = url;
      }
    }
  }

  setAddressComponents(address) {
    if (this.hasStreetNumberTarget) this.streetNumberTarget.value = address.street_number || "";
    if (this.hasRouteTarget) this.routeTarget.value = address.route || "";
    if (this.hasCityTarget) this.cityTarget.value = address.locality || "";
    if (this.hasCountyTarget) this.countyTarget.value = address.administrative_area_level_2 || "";
    if (this.hasStateTarget) this.stateTarget.value = address.administrative_area_level_1 || "";
    if (this.hasCountryTarget) this.countryTarget.value = address.country || "";
    if (this.hasPostalCodeTarget) this.postalCodeTarget.value = address.postal_code || "";
  }

  setGeometry(geometry) {
    console.log("long", geometry.location.lng().toString());
    console.log("lat", geometry.location.lat().toString());
    if (this.hasLongitudeTarget) this.longitudeTarget.value = geometry.location.lng().toString();
    if (this.hasLatitudeTarget) this.latitudeTarget.value = geometry.location.lat().toString();
  }

  // https://developers.google.com/maps/documentation/places/web-service/autocomplete
  get autocompleteOptions() {
    return {
      fields: ["place_id", "address_components", "geometry"],
      componentRestrictions: {
        country: this.countryValue,
      },
    };
  }

  preventSubmit(event) {
    if (event.code === "Enter") {
      event.preventDefault();
    }
  }

  formatAddressComponents(addressComponents) {
    const data = {};

    addressComponents.forEach((component) => {
      const type = component.types[0];

      data[type] = component.long_name;
    });

    return data;
  }
}

import { Controller } from "@hotwired/stimulus"
import { current } from "tailwindcss/colors"

// Connects to data-controller="send-text"
export default class extends Controller {
  static targets = [ "input"]
  connect() {
    console.log("SEND TEXT CONNECTED")
  }

  onClick(event) {
    event.preventDefault()
    const addText = event.target.dataset.text ||  event.target.innerText
    const currentText =  this.inputTarget.value
    if (currentText.includes(addText)) {
      return 
    } else { 
      this.inputTarget.value = addText + ": " + currentText 
      this.inputTarget.focus()
      var event = new Event('change', { bubbles: true });
      this.inputTarget.dispatchEvent(event);
      this.inputTarget.dispatchEvent(new window.Event('input', { bubbles: true }) ) 

    }
  }



}

import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    mask: String,
  };
  connect() {
    const options = {
      // mask: this.maskValue,
      // lazy: false,
      altInput: true,
      altFormat: "m/d/y",
      mode: "range"
    };
    this.pickerObject = flatpickr(this.element, options);
    console.log("FlatPicker: ", this.pickerObject, this.element);
  }
  disconnect(){
    this.pickerObject.destroy();
  }
}

import IMask from "imask";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    remove: String,
  };
  connect() {
    this.onPaste = this.onPaste.bind(this);
    this.element.addEventListener("paste", this.onPaste);
  }
  disconnect() {
    this.element.removeEventListener("paste", this.onPaste);
  }

  onPaste(event) {
    event.preventDefault();
    const text = (event.clipboardData || window.clipboardData).getData("text");
    let newText = text.replace(this.removeValue, "");

    console.log("PasteGuardController#onPaste", {
      text,
      newText,
    });
    this.element.value = newText;
  }
}

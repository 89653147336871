import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="asap-guard"
export default class extends Controller {
  static targets = [ "input", "message" ]
  connect() {
    console.log("ASAP GUARD CONNECTED")
    this.messageTarget.hidden = true
    this.inputTarget.oninput =  this.onInput.bind(this)
    this.onInput() 
  }

  onInput () { 
    let show_message = false 
    if (this.inputTarget.value.length <= 0) {
      this.messageTarget.hidden = true
      return 
    }
    console.log("ON INPUT", this.inputTarget.value)
    if (this.inputTarget.value.match(/asap/i)) {
      this.messageTarget.hidden = false 
    } else  { 
      this.messageTarget.hidden = true
    }
  }



}

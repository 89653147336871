import { Controller } from "@hotwired/stimulus";
//https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export default class extends Controller {
  static values = {
    delay: Number,
    method: String,
  };

  initialize() {}

  connect() {
    this.onChange = debounce(this.onChangeImmediate, 1000);

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 250);
  }

  async onChangeImmediate(event) {
    console.log("onChange Event", event);
    if (this.loading) {
      return;
    }
    const data = new FormData(this.element);

    const action = new URL(this.element.action);
    const json = Object.fromEntries(data.entries());
    const method = (data.get("_method") || "POST").toUpperCase();
    data.delete("_method");
    const csrfToken = document.getElementsByName("csrf-token")[0].content;
    const res = await fetch(this.element.action + ".json", {
      body: data,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      method,
    });

    console.log("Saved...", res.status);
    const jsonBody = await res.json();
    console.log("Saved...", jsonBody);
    if (jsonBody.changes) {
      Object.keys(jsonBody.changes).forEach((key) => {
        const domId = `draft_${key}`;
        const elem = document.getElementById(domId);
        if (elem) {
          elem.value = jsonBody.changes[key];
        } else {
          console.log("No element found for", domId);
        }
      });
    }
  }
}

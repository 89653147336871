import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetid: String,
  };

  onChange(event) {
    console.log("onChange Event", event);
    if (this.targetidValue == null) {
      return;
    }

    console.log("getElementByid", this.targetidValue);
    const valueField = document.getElementById(this.targetidValue);
    console.log("valuefield", valueField);
    if (!valueField) {
      return;
    }
    console.log("valuefield.value", valueField.value);
    if (valueField.value === "") {
      valueField.value = event.currentTarget.value;
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "doneElem", "actionElem"];
  static values = {
    doneMessage: String,
  }

  connect() {

  }

  copy(e) {
    e.preventDefault();
    if (!this.hasSourceTarget) {
      console.error("clipboard controller needs a source target");
      return 
    }
    navigator.clipboard.writeText(this.sourceTarget.textContent.trim()).then(() => {
      if (this.hasDoneMessageValue) {
        this.element.classList.add("bg-yellow-200");
        const oldHTML = this.element.innerHTML;
        this.element.innerHTML = this.doneMessageValue;
        setTimeout(() => {
          this.element.innerHTML = oldHTML;
          this.element.classList.remove("bg-yellow-200");
        }, 750) 
      }else if (this.hasDoneElemTarget) {
        this.doneElemTarget.classList.remove("hidden");
        this.actionElemTarget.classList.add("hidden");
        setTimeout(() => {
          this.doneElemTarget.classList.add("hidden");
          this.actionElemTarget.classList.remove("hidden");
        }, 1250) 
      } else {
        console.log('clipboard has nothing to show')
      }

    })

  }
}

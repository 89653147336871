import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;


import { Autocomplete } from "stimulus-autocomplete";
application.register("autocomplete", Autocomplete);

export { application };

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetid: String,
  };


  connect() {
    this.element.oninput = this.onChange.bind(this)
  }

  onChange(event) {
    let now = new Date()
    let day = ("0" + now.getDate()).slice(-2);
    let month = ("0" + (now.getMonth() + 1)).slice(-2);
    let today = now.getFullYear() + "-" + (month) + "-" + (day);

    let todayDate = new Date(today)

    let picked = new Date(this.element.value)

    if (picked < todayDate) {
      this.element.value = today
    }
  }
}

// app/javascript/controllers/focus_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.updateInputs()

    console.log("Focus controller connected")
    // Observe changes in the DOM to handle dynamically added inputs
    this.observer = new MutationObserver(() => this.updateInputs())
    this.observer.observe(this.element, { childList: true, subtree: true })
  }

  disconnect() {
    // Disconnect the observer when the controller is disconnected
    if (this.observer) {
      this.observer.disconnect()
    }

    // Clean up event listeners
    this.inputs.forEach((input) => {
      input.removeEventListener("keydown", this.handleKeydown)
    })
  }

  updateInputs() {
    this.inputs = this.element.querySelectorAll("input[type='text'], input[type='number']")
    this.inputs.forEach((input, index) => {
      input.removeEventListener("keydown", this.handleKeydown)
      input.addEventListener("keydown", this.handleKeydown.bind(this, index))
    })
  }

  handleKeydown(index, event) {
    if (event.key === "Enter") {

      event.preventDefault()
      this.inputs[index].dispatchEvent(new Event('change', { bubbles: true }))

      this.moveToNextInput(index)
    }
  }

  moveToNextInput(currentIndex) {
    const nextIndex = currentIndex + 1
    this.inputs[currentIndex].dispatchEvent(new Event('change', { bubbles: true }))
    if (nextIndex < this.inputs.length) {
      this.inputs[nextIndex].dispatchEvent(new Event('change', { bubbles: true }))
      this.inputs[nextIndex].focus()
    }
  }
}
import IMask from "imask";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    mask: String,
  };
  connect() {
    const options = {
      mask: this.maskValue,
      lazy: false,
    };
    this.mask = IMask(this.element, options);
    this.element.iMaskObject = this.mask;
    console.log("MASK: ", this.maskValue, this.element);
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [
    "input"
  ]

  static values = {
    containerId: String

  }

  connect() {
    console.log("toggle_pickup_list:: Connected!", this.element, this.containerIdValue, this.inputTarget)
    this.onValueChanged()

  }

  onValueChanged(event) {
    console.log("toggle_pickup_list:: onValueChanged", event, this.element, this.inputTarget)
    const container = document.getElementById(this.containerIdValue)
    const selects = container.getElementsByTagName('select')
    for (let select of selects) {
      // select the last option in the select element 
      if (this.inputTarget.checked ) { 

        select.selectedIndex = select.length - 1
      } else { 
        select.selectedIndex = 0
      }
      // trigger the change event on the select element
      select.dispatchEvent(new Event('change'))
    }



  }




}
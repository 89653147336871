import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "select", 
    "dispamount", 
    "row"
  ] 


  static values = {
    qty: Number

  }

  static classes = ["full", "partial", "cleanup"]

  connect() {
    console.log("Connected!", this.selectTarget, this.rowTargets, this.highlightClass, this.qtyValue)
    this.onValueChanged()

  }

  onValueChanged(event) {
    // check to see if the value of select is greater than zero 
      this.rowTargets.forEach((row) => {
        // if  select >= qty, add the full class and remove all others 
        // if select > 0 && select < qty, add the partial class and remove all others
        // else add the cleanup class and remove all others
        if (this.selectTarget.value >= this.qtyValue) {
          row.classList.add(...this.fullClasses)
          row.classList.remove(...this.partialClasses)
          row.classList.remove(...this.cleanupClasses)
        } else if (this.selectTarget.value > 0 && this.selectTarget.value < this.qtyValue) {
          row.classList.add(...this.partialClasses)
          row.classList.remove(...this.fullClasses)
          row.classList.remove(...this.cleanupClasses)
        }
        else {
          row.classList.add(...this.cleanupClasses)
          row.classList.remove(...this.fullClasses)
          row.classList.remove(...this.partialClasses)
        }

      })

        if (this.selectTarget.value === -1 || this.selectTarget.value === "-1") { 
            this.dispamountTarget.innerText = "SOME "
        } else { 
          this.dispamountTarget.innerText = this.selectTarget.value  || 0
        }



  }




}